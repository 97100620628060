/* .specific-page {
  min-height: 100vh;
  background-color: #ca9de4e0;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 60;
  font-style: normal;
 
} */

.specific-page {
  min-height: 100vh;
  background-color: #2a282be0;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 60;
  font-style: normal;
 
}